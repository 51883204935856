import { memo, useMemo } from 'react';

import { IArticleProduct } from '@models/article.interface';
import ArticlesIcon from '@components/core/Icons/Articles';
import VideoIcon from '@components/core/Icons/Video';
import PodcastIcon from '@components/core/Icons/Podcast';
import Variants from './Variants';

export type CardVariantType = 'vertical' | 'horizontal';
export interface ICardProps {
  id: string;
  url: string;
  background?: string;
  time: string | number;
  title: string;
  type: any;
  episode?: string;
  rating?: number | string;
  store_product?: IArticleProduct;
  slug: string;
  variant?: CardVariantType;
  onClickFavorite?: (contentId: string) => void;
  showModalConfirm?: boolean;
  description?: string;
  webserieStyle?: boolean;
  indexEpisode?: string;
  onClickCard?: () => void;
  notRedirect?: boolean;
  webserieTitle?: string;
  webserieSlug?: string;
  seasonTitle?: string;
}

export interface ICardVariantProps
  extends Pick<
    ICardProps,
    | 'id'
    | 'url'
    | 'slug'
    | 'type'
    | 'background'
    | 'store_product'
    | 'time'
    | 'episode'
    | 'webserieTitle'
    | 'webserieSlug'
    | 'seasonTitle'
    | 'rating'
    | 'title'
    | 'onClickFavorite'
    | 'showModalConfirm'
    | 'description'
    | 'webserieStyle'
    | 'indexEpisode'
    | 'onClickCard'
    | 'notRedirect'
  > {
  icon?: JSX.Element;
}

const Card: React.FC<ICardProps> = ({
  background,
  type,
  variant = 'vertical',
  onClickCard,
  ...props
}) => {
  const contentTypeIcon = useMemo(() => {
    const icons = {
      article: <ArticlesIcon />,
      video: <VideoIcon />,
      podcast: <PodcastIcon />,
    };

    return icons[type];
  }, [type]);

  const correctImage = useMemo(() => {
    if (background) return background;
    return '/share.webp';
  }, [background]);

  return (
    <Variants
      variant={variant}
      background={correctImage}
      icon={contentTypeIcon}
      type={type}
      {...props}
      onClickCard={onClickCard}
    />
  );
};

export default memo(Card);
