import { useRouter } from 'next/router';
import { useUser } from '@hooks/user';
import { useAuth } from '@hooks/auth';
import { useTracking } from '@hooks/tracking';
import { IArticleProduct } from '@models/article.interface';
import { Rating, RatingProps } from '@startse/react-components';
import ShareArea from '@components/core/Share/ShareArea';

export interface IRatingBase
  extends Pick<RatingProps, 'contentId' | 'type' | 'theme'> {
  contentRelatedProduct?: IArticleProduct;
}

const RatingBase: React.FC<IRatingBase> = ({
  contentId,
  type,
  contentRelatedProduct,
  theme,
}) => {
  const { user } = useUser();
  const { setShowModalLogin } = useAuth();
  const { track } = useTracking();
  const router = useRouter();
  const { slug = '', video = '', slugEpisode = '' } = router.query;

  const submitAskCallback = (response: any) => {
    if (router.pathname.includes('/webseries')) {
      track('Click in evaluate episode in webserie', {
        episodeSlug: slugEpisode || slug,
        rate: response.score,
      });
    }

    track('Evaluate content', {
      slug: slug || video,
      type,
      rate: response.score,
      product_id: contentRelatedProduct?.id,
      product_name: contentRelatedProduct?.name,
    });
  };

  const noUserIdClicked = () => {
    setShowModalLogin(true);
  };

  return (
    <Rating
      contentId={contentId}
      type={type}
      userId={user?._id}
      ShareComponent={<ShareArea />}
      rateCallback={submitAskCallback}
      theme={theme}
      noUserIdClicked={noUserIdClicked}
    />
  );
};

export default RatingBase;
