import { useState } from 'react';
import { ContainerMenu, ToggleButton, ToggleButtonGroup } from './styles';

interface IValues {
  label: string;
  value: string;
}
interface BasicTabsProps {
  values: IValues[];
  selectedValue: string;
  setSelectedValue(e: any): void;
}

const BasicTabs: React.FC<BasicTabsProps> = ({
  values,
  selectedValue,
  setSelectedValue,
}) => {
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedValue(newValue);
  };
  return (
    <ContainerMenu>
      <ToggleButtonGroup
        value={selectedValue}
        exclusive
        onChange={handleChange}
        aria-label="text alignment"
      >
        {values.map(e => (
          <ToggleButton value={e.value}>
            <p>{e.label}</p>
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </ContainerMenu>
  );
};

export default BasicTabs;
