import {
  useEffect,
  useState,
  useCallback,
  useImperativeHandle,
  forwardRef,
} from 'react';
import Plyr from 'plyr';
import 'plyr/dist/plyr.css';

import { Container } from '../styles';

export interface PlayerProps {
  vimeoId: string;
  videoUrl: string;
  volume?: number;
  togglePlayer?: boolean;
  getVolume?: (volume: number) => void;
  currentTime?: number;
  onPlay?: (event: Plyr.PlyrEvent) => any;
  onEnded?: (event: Plyr.PlyrEvent) => void;
  OnVolumeChange?: (event: Plyr.PlyrEvent) => void;
  getDuration?: (duration: number) => void;
  onDurationChange?: (duration: number) => void;
  getTitle?: (title: string) => void;
  autoplay?: boolean;
}

export interface PlayerRefProps {
  handleFullscreen(fullscreen: boolean): void;
}

const Player: React.ForwardRefRenderFunction<PlayerRefProps, PlayerProps> = (
  {
    vimeoId,
    volume = 1,
    getVolume,
    togglePlayer,
    currentTime,
    onPlay,
    onEnded,
    OnVolumeChange,
    getDuration,
    onDurationChange,
    getTitle,
    autoplay = false,
  },
  ref,
) => {
  const [playerState, setPlayerState] = useState<Plyr>();

  const handleSetVolume = useCallback(
    (value: number) => {
      if (playerState) {
        playerState.volume = value;
      }
    },
    [playerState],
  );

  const handleFullscreen = useCallback(
    (fullscreen: boolean) => {
      if (playerState) {
        if (fullscreen) playerState.fullscreen.enter();
        else playerState.fullscreen.exit();
      }
    },
    [playerState],
  );

  useImperativeHandle(ref, () => ({
    handleFullscreen,
  }));

  const handlePausePlayer = useCallback(() => {
    if (playerState) {
      playerState.pause();
    }
  }, [playerState]);

  const handleStartPlayer = useCallback(() => {
    if (playerState) {
      playerState.play();
    }
  }, [playerState]);

  const handleGetVolume = useCallback(() => {
    if (playerState && getVolume) {
      const actualVolume = playerState.volume;
      getVolume(actualVolume);
    }
  }, [playerState, getVolume]);

  const handleSetCurrentTime = useCallback(() => {
    if (currentTime && playerState) {
      playerState.currentTime = currentTime;
    }
  }, [playerState, currentTime]);

  const CleanCurrentTime = useCallback(() => {
    if (playerState) {
      playerState.off('play', handleSetCurrentTime);
    }
  }, [playerState, handleSetCurrentTime]);

  const handleOnPlay = useCallback(
    (event: Plyr.PlyrEvent) => {
      if (onPlay) {
        const resp = onPlay(event);

        if (resp === 'pause' && playerState) {
          playerState.pause();
        }
      }
    },
    [onPlay, playerState],
  );

  const CleanOnPlay = useCallback(() => {
    if (playerState) {
      playerState.off('play', handleOnPlay);
    }
  }, [playerState, handleOnPlay]);

  const handleOnEnded = useCallback(
    (event: Plyr.PlyrEvent) => {
      if (onEnded) {
        onEnded(event);
      }
    },
    [onEnded],
  );

  const CleanOnEnded = useCallback(() => {
    if (playerState) {
      playerState.off('ended', handleOnEnded);
    }
  }, [playerState, handleOnEnded]);

  const handleOnVolumeChange = useCallback(
    (event: Plyr.PlyrEvent) => {
      if (OnVolumeChange) {
        OnVolumeChange(event);
      }
    },
    [OnVolumeChange],
  );

  const CleanOnVolumeChange = useCallback(() => {
    if (playerState) {
      playerState.off('volumechange', handleOnVolumeChange);
    }
  }, [playerState, handleOnVolumeChange]);

  const handleGetDuration = useCallback(async () => {
    if (playerState && getDuration) {
      const duration = await playerState.embed.getDuration();
      getDuration(duration);
    }
  }, [playerState, getDuration]);

  const handleOnDurationChange = useCallback(() => {
    if (onDurationChange && playerState) {
      onDurationChange(playerState.currentTime);
    }
  }, [playerState, onDurationChange]);

  const cleanDuration = useCallback(() => {
    if (playerState) {
      playerState.off('timeupdate', handleOnDurationChange);
    }
  }, [playerState, handleOnDurationChange]);

  const handleGetVideoTitle = useCallback(async () => {
    if (playerState && getTitle) {
      const title = await playerState.embed.getVideoTitle();
      getTitle(title);
    }
  }, [playerState, getTitle]);

  useEffect(() => {
    const options = {
      autoplay,
      controls: [
        'play',
        'progress',
        'current-time',
        'duration',
        'mute',
        'volume',
        'settings',
        'fullscreen',
        'captions',
      ],
      fullscreen: {
        enabled: true,
        fallback: true,
        iosNative: false,
        container: null,
      },
    };

    const player = Plyr.setup('#player', options)[0];

    player.once('ready', function HandlePlayerReady() {
      setPlayerState(player);
    });
    return () => {
      if (player) {
        player.destroy();
      }
    };
  }, [autoplay]);

  useEffect(() => {
    setPlayerState(prevState => {
      if (prevState) {
        prevState.source = {
          type: 'video',
          sources: [
            {
              src: vimeoId,
              provider: 'vimeo',
            },
          ],
        };
        return prevState;
      }
      return undefined;
    });
  }, [vimeoId]);

  useEffect(() => {
    if (volume >= 0 && volume < 1) {
      handleSetVolume(volume);
    }
  }, [volume, handleSetVolume]);

  useEffect(() => {
    if (togglePlayer) {
      handleStartPlayer();
    } else {
      handlePausePlayer();
    }
  }, [togglePlayer, handlePausePlayer, handleStartPlayer]);

  useEffect(() => {
    handleGetVolume();
  }, [handleGetVolume]);

  useEffect(() => {
    if (playerState) {
      playerState.once('play', handleSetCurrentTime);
    }
    return () => {
      CleanCurrentTime();
    };
  }, [currentTime, playerState, handleSetCurrentTime, CleanCurrentTime]);

  useEffect(() => {
    if (playerState) {
      playerState.on('play', handleOnPlay);
    }
    return () => {
      CleanOnPlay();
    };
  }, [onPlay, playerState, handleOnPlay, CleanOnPlay]);

  useEffect(() => {
    if (onEnded && playerState) {
      playerState.on('ended', handleOnEnded);
    }

    return () => {
      CleanOnEnded();
    };
  }, [onEnded, playerState, handleOnEnded, CleanOnEnded]);

  useEffect(() => {
    if (OnVolumeChange && playerState) {
      playerState.once('volumechange', handleOnVolumeChange);
    }
    return () => {
      CleanOnVolumeChange();
    };
  }, [OnVolumeChange, playerState, handleOnVolumeChange, CleanOnVolumeChange]);

  useEffect(() => {
    handleGetDuration();
  }, [handleGetDuration]);

  useEffect(() => {
    if (playerState) {
      playerState.on('timeupdate', handleOnDurationChange);
    }
    return () => {
      cleanDuration();
    };
  }, [onDurationChange, playerState, handleOnDurationChange, cleanDuration]);

  useEffect(() => {
    handleGetVideoTitle();
  }, [handleGetVideoTitle]);

  return (
    <Container className="plyr__video-embed" id="player">
      <iframe
        title={`Video ${vimeoId}`}
        src={`https://player.vimeo.com/video/${vimeoId}`}
        allowFullScreen
        frameBorder="0"
      />
    </Container>
  );
};

export default forwardRef(Player);
