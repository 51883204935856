import dynamic from 'next/dynamic';
import { memo } from 'react';

import { ICardProps } from './Card';

const ListDesktop = dynamic(() => import('./ListDesktop'));
const ListMobile = dynamic(() => import('./ListMobile'));

export interface ListProps {
  contents?: ICardProps[];
  onClick?(index: number): void;
  className?: string;
}

const List: React.FC<ListProps> = props => {
  return (
    <>
      <ListDesktop {...props} />
      <ListMobile {...props} />
    </>
  );
};

export default memo(List);
