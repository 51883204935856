import { AdLoginProvider } from './adLogin';
import { UserProvider } from './user';
import { AuthProvider } from './auth';
import { LoadingProvider } from './loading';
import { SnackbarProvider } from './snackbar';
import { TrackingProvider } from './tracking';
import { FavoriteProvider } from './favorite';
import { EventProvider } from './event';
import { PromptProvider } from './prompt';

const AppProvider: React.FC = ({ children }) => {
  return (
    <TrackingProvider>
      <PromptProvider>
        <LoadingProvider>
          <UserProvider>
            <AuthProvider>
              <EventProvider>
                <FavoriteProvider>
                  <SnackbarProvider>
                    <AdLoginProvider>{children}</AdLoginProvider>
                  </SnackbarProvider>
                </FavoriteProvider>
              </EventProvider>
            </AuthProvider>
          </UserProvider>
        </LoadingProvider>
      </PromptProvider>
    </TrackingProvider>
  );
};

export default AppProvider;
