import { Box } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import styledComponent from 'styled-components';

export const Container = styled('footer')(() => ({
  padding: '0 25px',
  display: 'flex',
  position: 'fixed',
  zIndex: 2,
  flexDirection: 'column',
  bottom: 0,
  borderTop: '1px solid #00000029',
  boxShadow: '0px -1px 15px 0px #00000048',
  alignItems: 'left',
  backgroundColor: '#FFFFFF',
  '& p': {
    fontSize: 12,
    fontFamily: 'Barlow',
    lineHeight: '16px',
    color: '#333333',
    listStyleType: 'none',
    margin: '6px',
  },
  width: 'calc(100% - 100px)',
  '@media screen and (max-width: 780px)': {
    width: '100%',
  },
}));

export const ContainerLogo = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  '@media screen and (max-width: 780px)': {
    display: 'none',
  },
}));

export const ContainerTop = styled('div')(() => ({
  paddingBottom: '18px',
  transition: 'transform 0.4s ease',
  backgroundColor: '#FFFFFF',
  overflow: 'hidden',
  bottom: 50,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderBottom: '1px solid gray',
  '@media screen and (max-width: 780px)': {
    justifyContent: 'center',
  },
}));
export const ContainerBottom = styled('div')(() => ({
  paddingTop: '18px',
  backgroundColor: '#FFFFFF',
  display: 'flex',
  zIndex: 3,
  flex: 1,
  justifyContent: 'space-between',
  flexWrap: 'wrap',
  alignItems: 'center',
  '& p': {
    marginLeft: 0,
  },
  '@media screen and (max-width: 780px)': {
    flexDirection: 'column',
    marginTop: 10,
    '& p': {
      textAlign: 'center',
    },
  },
}));
export const Info = styled('div')(() => ({
  marginLeft: '22px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'left',
  flex: 1,
  '@media screen and (max-width: 780px)': {
    alignItems: 'center',
  },
}));
export const InfoSubContainer = styled('div')(() => ({
  marginTop: '6px',
  display: 'flex',
  justifyContent: 'space-between',
  '@media screen and (max-width: 780px)': {
    flexDirection: 'column',
    flexWrap: 'wrap',
    alignItems: 'center',
    width: '100vw',
    margin: 0,
    '@media screen and (max-width: 780px)': {
      marginBottom: 10,
    },
  },
}));

export const PrivacyLinksText = styled('span')(({ theme }) => ({
  textAlign: 'right',
  display: 'flex',
  alignItems: 'center',
  '& a': {
    fontSize: 12,
    color: theme.palette.primary.main,
  },
  '@media screen and (max-width: 780px)': {
    '& a': {
      fontSize: 8,
      textAlign: 'center',
    },
    '& p': { visibility: 'hidden' },
  },
}));

export const Bold = styled('span')(() => ({
  fontWeight: 700,
}));
export const Copyright = styled('span')(() => ({
  boxShadow: 'inset 3px 0px 6px #00000029',
  border: '1px solid #00000029',
  borderRadius: '20px 20px 0px 0px',
  width: '100%',
  fontSize: 12,
  fontFamily: 'Barlow',
  lineHeight: '16px',
  color: '#333333',
  listStyleType: 'none',
  textAlign: 'center',
  marginTop: '8px',
  paddingTop: '8px',
  paddingBottom: '8px',
}));

export const ContainerArrow = styledComponent.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 8em;
  height: 1.1em;
  border-top-right-radius: 14px;
  border-top-left-radius: 14px;
  border: 1px solid #00000029;
  background-color: #FFFFFF;
  box-shadow: 0px -3px 15px 0px #00000048;
  position: relative;
  bottom: 1.1em;
  right: calc(150px - 80vw);
  cursor: pointer;
  z-index: 2;
  svg {
    width: 0.5em;
    transition: all 0.5s;
  }
  transition: all 0.5s;
  overflow: 'hidden';

  
`;
