import { memo } from 'react';

import Favorite from '../../../../assets/svg/favorite.svg';
import { ContainerDesktop, useStyles } from '../styles';

interface DesktopButtonProps {
  toggle: boolean;
  disableButton: boolean;
  handleFavorite(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void;
  theme?: 'white' | 'dark' | 'superdark';
}

const DesktopButton: React.FC<DesktopButtonProps> = ({
  toggle,
  handleFavorite,
  disableButton,
  theme = 'white',
}) => {
  const themes = useStyles({ toggle });

  return (
    <ContainerDesktop
      aria-label="favoritar"
      onClick={handleFavorite}
      disabled={disableButton}
      className={themes[theme]}
    >
      <Favorite />
      SALVAR
    </ContainerDesktop>
  );
};

export default memo(DesktopButton);
