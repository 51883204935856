import dynamic from 'next/dynamic';
import { CardVariantType, ICardVariantProps } from '..';

const Vertical = dynamic(() => import('./Vertical'));
const Horizontal = dynamic(() => import('./Horizontal'));

interface IVartiantsProps extends ICardVariantProps {
  variant: CardVariantType;
}

const Vartiants: React.FC<IVartiantsProps> = ({ variant, ...props }) => {
  const components = {
    vertical: <Vertical {...props} />,
    horizontal: <Horizontal {...props} />,
  };

  return components[variant];
};

export default Vartiants;
